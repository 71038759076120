























import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskPracticeQuestionResource } from '@/store/types'

@Component
export default class TaskPracticeInformationQuestion extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskPracticeQuestionResource

  private isVisible = false

  private get labelApperance() {
    return this.isVisible ? 'orange--text' : 'teal--text text--darken-4'
  }

  private get iconApperance() {
    return this.isVisible ? 'orange' : 'teal darken-4'
  }

  private toggleQuestionApperance() {
    this.isVisible = !this.isVisible
  }
}
