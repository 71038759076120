











import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskResource } from '@/store/types'
import TaskPracticeInformationQuestion from '@/components/views/exercise/TaskPracticeInformationQuestion.vue'

@Component({
  components: { TaskPracticeInformationQuestion },
})
export default class TaskPracticeInformation extends Vue {
  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }
}
