




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

import CardHint from '@/components/_uikit/CardHint.vue'
import FilesList from '@/components/FilesList.vue'
import { EducationLargeExerciseResource, EducationLargeTaskResource, ExerciseStatus } from '@/store/types'
import { convertSecondsToHoursMinutes } from '@/utils/functions'

@Component({
  components: { CardHint, FilesList },
})
export default class TaskCommonInformation extends Vue {
  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get isStatusWait () {
    const { value } = this.exercise.progress.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private get time() {
    const startTimestamp = this.exercise.progress.workStartedAt ? (DateTime.fromSQL(this.exercise.progress.workStartedAt, { zone: 'Europe/Moscow' }) as any).ts / 1000 : 0
    const endTimestamp = this.exercise.progress.onCheckAt ? (DateTime.fromSQL(this.exercise.progress.onCheckAt, { zone: 'Europe/Moscow' }) as any).ts / 1000 : 0
    const duration = endTimestamp - startTimestamp
    return {
      duration: this.exercise.avgExecutionSeconds ? convertSecondsToHoursMinutes(duration, true) : '',
      recommend: this.exercise.avgExecutionSeconds ? convertSecondsToHoursMinutes(this.exercise.avgExecutionSeconds, true) : '',
    }
  }
}
